* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  background-color: #f4f6f8;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

/* code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
} */

*::-webkit-scrollbar-track,
table tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0;
  background-color: #f5f5f5;
  border-radius: 10px;
}

*::-webkit-scrollbar,
table tbody::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb,
table tbody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a4a4a4;
}

.reduced-width {
  width: 50%;
  padding: 20px;
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .reduced-width {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .reduced-width {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .reduced-width {
    width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .reduced-width {
    width: 95%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .reduced-width {
    width: 95%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .reduced-width {
    width: 100%;
  }
}
@media only screen and (min-width: 1500px) {
  .reduced-width {
    width: 70%;
  }
}
@media only screen and (min-width: 1900px) {
  .reduced-width {
    width: 60%;
  }
}
@media only screen and (min-width: 1950px) {
  .reduced-width {
    width: 50%;
  }
}

.medium-width {
  width: 70%;
  padding: 20px;
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .medium-width {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .medium-width {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .medium-width {
    width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .medium-width {
    width: 90%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .medium-width {
    width: 80%;
  }
}
@media only screen and (min-width: 1500px) {
  .medium-width {
    width: 65%;
  }
}

.container {
  width: 100%;
  padding: 20px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-container {
  width: 100%;
  padding: 20px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    width: 100%;
  }
  .dashboard-container {
    width: 95%;
  }
}

/* Large devices (laptops/desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container {
    width: 80%;
  }
}

.gm-fullscreen-control {
  display: none !important;
}

.hotspot-map div:first-child {
  width: 100%;
}

.fleet-details-map div:first-child {
  width: 100%;
  height: 100%;
}

.hotspot-map .gm-style .gmnoprint:last-child {
  width: 100%;
}

.table-wrapper {
  overflow-x: auto;
}

.l-flex {
  display: flex;
  align-items: flex-end;
}
